export default {
  data: () => {
    return {
      infoPopup: false,
      replaces: {
        c: "seat-business",
        st: "seat-econom",
        az: "seat-econom-a-zone",
        bz: "seat-econom-b-zone",
        sp: "seat-space-plus",
        ss: "seat-super-space",
        fr: "seat-econom-front-rows",
        sud: "seat-space-plus"
      },
      features: {
        na: "-fixed-arms",
        lr: "-fixed",
        aa: "",
        bmp: ""
      }
    };
  },
  watch: {
    infoPopup(value) {
      if (value) {
        document.documentElement.classList.add("seat-modal-opened");
      } else {
        document.documentElement.classList.remove("seat-modal-opened");
      }
    }
  }
};
